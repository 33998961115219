import React from "react";
import MainContent from "../../../Components/HRTabsMainContent.jsx"; // Import MainContent
import { progressTabContent } from "../../../Assets/HRStaticContent.js"; // Import the content

// import "./JobsTab.css"; // Optional: import specific styles

const ProgressTab = () => {
  return (
    <div className="progress-tab">
      <MainContent
        customText={progressTabContent}
        onJobSelect={() => {}} // Define your onJobSelect if needed
      />
    </div>
  );
};

export default ProgressTab;
