// src/content.js

export const hrBasePageContent = {
  title: "SkillsBridge.AI helps connect aspirations of people and business",
  paragraphs: [
    "At GMR, we believe in the power of nurturing careers. Investing in our workforce can lead to better productivity, higher motivation, and lower hiring expenses. SkillsBridge.AI is a cloud-based software that helps our people enhance skills and achieve their career goals. It uses advanced generative AI to identify skills gaps, develop critical skills, and strategically deploy them where they’re needed the most.",
    "This is a tool to up-skill and re-skill our people, not to assess performance. Therefore, line managers do not have access to the skills and career opportunities of their teams, displayed in the SkillsBridge.AI platform. Employees may contact their HR partners directly for support.",
  ],
};

export const jobTabContent = {
  title: "View SkillsBridge.AI generated JD & extent of automation for",
  description:
    "View SkillsBridge.AI generated job descriptions for your chosen selection, and the extent of job automation that can be achieved with advanced technologies that are being used in this industry across the world at present.",
  stepsTitle: "SkillsBridge.AI will take the following steps:",
  steps: [
    "1. Find relevant roles for the chosen selection.",
    "2. Create modern job descriptions for each role selected, taking into account available technologies.",
    "3. Compare with our proprietary Job Reference Database and find the extent of job automation.",
  ],
};

export const skillsTabContent = {
  title: "View skills gaps for",
  description:
    "Constant technological advancements create skills gaps in people and organizations. SkillsBridge.AI identifies skills gaps by comparing skills that your people and organization have, or that your job descriptions require, with a proprietary Job Reference Database that is continuously updated to keep it contemporary.",
};

export const learningTabContent = {
  title: "View recommended learning programs for",
  description: [
    "View learning programs available to various groups of people in the organization.",
    "SkillsBridge.AI will find relevant learning programs from its catalog of top-rated programs from all learning providers that have been reviewed and certified by SkillsBridge.AI or by your organization",
  ],
};

export const progressTabContent = {
  title: "Measure progress for",
  description: "Track progress of skills development by your chosen selection.",
};

export const opportunitiesTabContent = {
  title: "View open roles/opportunities for",
  description:
    "Find open job opportunities for your selection, that are posted in internal databases across the organization.",
};
