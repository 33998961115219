// src/ProtectedRoute.js

import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../State/AuthContext";

const ProtectedRoute = ({ children }) => {
  const { email } = useContext(AuthContext); // Get the user's email from context

  // If no email, redirect to the login page
  if (!email) {
    return <Navigate to="/" />;
  }

  // If email exists, allow access to the route
  return children;
};

export default ProtectedRoute;
