// src/content.js

export const jobBasePageContent = {
  title: "SkillsBridge.AI helps connect aspirations of people and business",
  paragraphs: [
    "At GMR, we believe in the power of nurturing careers. Investing in our workforce can lead to better productivity, higher motivation, and lower hiring expenses. SkillsBridge.AI is a cloud-based software that helps our people enhance skills and achieve their career goals. It uses advanced generative AI to identify skills gaps, develop critical skills, and strategically deploy them where they’re needed the most.",
    "This is a tool to up-skill and re-skill our people, not to assess performance. Therefore, line managers do not have access to the skills and career opportunities of their teams, displayed in the SkillsBridge.AI platform. Employees may contact their HR partners directly for support."
  ]
};

export const skillsTabContent = {
  title: "View and Validate your skills",
  paragraphs: [
    "Based on available inputs and data, SkillsBridge.AI has inferred the following skills that you are likely to have. Please take a few minutes to validate these. This will help us recommend the right learning programs in the 'Learning' tab and career opportunities in the 'Opportunities' tab. You may change proficiency ratings by moving the sliders on the 0-7 scale.",
    "If you make a change beyond +/- 1, please select the explanation that is the closest match to your situation. Upload evidences that support your explanation, such as a certificate or a video, which will help our AI engine to review and revise your proficiency. It may ask you to upload more before it can match your validation.",
    "Rest assured that this information is used only for your development, and not for any kind of performance assessment",
  ],
};

export const learningTabContent = {
  title: "Measure progress for",
  description: "Track progress of skills development by your chosen selection.",
};

export const opportunitiesTabContent = {
  title: "View open roles/opportunities for",
  description:
    "Find open job opportunities for your selection, that are posted in internal databases across the organization.",
};