import React from "react";
import { Route } from "react-router-dom";
import HRBasePage from "../Pages/HRAccess/HRBasePage";
import HRJobsTab from "../Pages/HRAccess/Tabs/JobTab/JobsTab";
import HRSkillsTab from "../Pages/HRAccess/Tabs/SkillsTab/SkillsTab";
import HRLearningTab from "../Pages/HRAccess/Tabs/LearningTab/LearningTab";
import HRProgressTab from "../Pages/HRAccess/Tabs/ProgressTab";
import HROpportunityTab from "../Pages/HRAccess/Tabs/OpportunityTab";
import ProtectedRoute from "./ProtectedRoute";

export const HRRoutes = () => {
  return (
    <Route
      path="/hr"
      element={
        <ProtectedRoute>
          <HRBasePage />
        </ProtectedRoute>
      }
    >
      <Route path="jobs" element={<HRJobsTab />} />
      <Route path="skills" element={<HRSkillsTab />} />
      <Route path="learning" element={<HRLearningTab />} />
      <Route path="progress" element={<HRProgressTab />} />
      <Route path="opportunity" element={<HROpportunityTab />} />
    </Route>
  );
};
