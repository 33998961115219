import React from "react";
import "./DropdownMenu.css"; // Custom CSS for styling

const DropdownMenu = ({ options, placeholder, onItemSelect, value }) => {
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    onItemSelect(selectedValue); // Call the callback to return the selected value
  };

  return (
    <div className="dropdown-section">
      <select onChange={handleSelectChange} value={value}>
        <option value="">{placeholder}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropdownMenu;
