import React, { useContext, useEffect } from "react";
import MainContent from "../../../../Components/HRTabsMainContent.jsx";
import SkillsDetail from "./SkillsDetails.jsx"; // Import your SkillsDetail component
import { skillsTabContent } from "../../../../Assets/HRStaticContent.js"; // Assuming you have content for skills
import { AuthContext } from "../../../../State/AuthContext.js";
import { useSelection } from "../../../../Hooks/TabsStateContext.js";

const SkillsTab = () => {
  const { selectedJob, setSelectedJob, selectedItem } = useSelection();
  const { email } = useContext(AuthContext);

  const handleJobSelect = (job) => {
    setSelectedJob(job);
  };

  // Effect to listen for changes in selectedItem and selectedJob
  useEffect(() => {
    if (!selectedItem) {
      setSelectedJob(null); // If no item is selected, reset selectedJob
    }
  }, [selectedItem, setSelectedJob]);

  // Check if no dropdown option is selected or if selectedJob is null
  if (!selectedItem || !selectedJob) {
    return (
      <div className="skills-tab">
        <MainContent
          customText={skillsTabContent} // Pass the title/content to MainContent
          onJobSelect={handleJobSelect}
        />
      </div>
    );
  }

  // Render JobDetail if a job is selected
  return (
    <div className="skills-tab">
      <SkillsDetail jobId={selectedJob.id} />
    </div>
  );
};

export default SkillsTab;
