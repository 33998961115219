import React from "react";
import { TabStateProvider } from "../Hooks/TabsStateContext";
import { AuthProvider } from "../State/AuthContext";
import { NotificationProvider } from "../State/NotificationContext";

export const AppProviders = ({ children }) => {
  return (
    <AuthProvider>
      <NotificationProvider>
        <TabStateProvider>{children}</TabStateProvider>
      </NotificationProvider>
    </AuthProvider>
  );
};
