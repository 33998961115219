import React from "react";
import MainContent from "../../../Components/EmployeeTabsMainContent"; // Import MainContent
// import "./JobsTab.css"; // Optional: import specific styles

const OpportunityTab = () => {
  return (
    <div className="opportunity-tab">
      <MainContent />
    </div>
  );
};

export default OpportunityTab;
