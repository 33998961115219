// ScrollableBox.js
import React from "react";
import "./ScrollableBox.css"; // Custom CSS for scrollable box styling

const ScrollableBox = ({ height, width, content, className }) => {
  const boxStyle = {
    height: height || "200px", // Default height
    width: width || "300px", // Default width
    overflowY: "scroll", // Enable vertical scrolling
    border: "1px solid #ccc", // Example border (can be customized)
    padding: "10px",
  };

  return (
    <div className={className} style={boxStyle}>
      {content}
    </div>
  );
};

export default ScrollableBox;
