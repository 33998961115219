import React from "react";
import MainContent from "../../../Components/EmployeeTabsMainContent"; // Import MainContent
// import "./JobsTab.css"; // Optional: import specific styles

const LearningTab = () => {
  return (
    <div className="learning-tab">
      <MainContent />
    </div>
  );
};

export default LearningTab;
