// src/components/JobDetail/JobDetailChart.js
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Register Chart.js components and the plugin
ChartJS.register(Title, Tooltip, Legend, ArcElement, ChartDataLabels);

function JobDetailChart({ data }) {
  const [averageAutomation, unaffectedPercentage] = data;

  const chartData = {
    labels: ["Average Automation Potential", "Unaffected"],
    datasets: [
      {
        label: "Automation Potential",
        data: [averageAutomation, unaffectedPercentage],
        backgroundColor: ["#00C8C8", "#ccc"],
        hoverBackgroundColor: ["#00C8C8", "#ccc"],
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        display: true,
        anchor: "end", // Position label to the end of the segment
        align: "start", // Align label to the start of the segment
        formatter: (value, context) => {
          const label = context.chart.data.labels[context.dataIndex];
          return `${value}%`;
        },
        color: "#fff",
        backgroundColor: "#000",
        borderRadius: 3,
        padding: 6,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.dataset.label || "";
            const value = tooltipItem.raw;
            return `${value}%`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div
      className="chart-container"
      style={{ position: "relative", height: "300px", width: "300px" }}
    >
      <Doughnut data={chartData} options={options} />
    </div>
  );
}

export default JobDetailChart;
