import React from "react";
import MainContent from "../../../../Components/HRTabsMainContent.jsx"; // Import MainContent
import { learningTabContent } from "../../../../Assets/HRStaticContent.js"; // Import the content

// import "./JobsTab.css"; // Optional: import specific styles

const LearningTab = () => {
  return (
    <div className="learning-tab">
      <MainContent
        customText={learningTabContent}
        onJobSelect={() => {}} 
      />
    </div>
  );
};

export default LearningTab;
