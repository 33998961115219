// src/Components/PromptBox.jsx
import React, { useState } from "react";
import { useNotification } from "../State/NotificationContext";

const PromptBox = ({ onSavePrompt }) => {
  const { triggerNotification } = useNotification();
  const [prompt, setPrompt] = useState("");

  const handleSubmitPrompt = (e) => {
    e.preventDefault();

    if (prompt) {
      onSavePrompt(prompt); // Pass the prompt to the parent component
      triggerNotification("Prompt saved successfully!");
    } else {
      alert("Please enter a prompt before saving.");
    }
  };

  return (
    <div className="prompt-input-section">
      <h3>Submit a Prompt</h3>
      <form onSubmit={handleSubmitPrompt}>
        <textarea
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Enter your prompt"
          required
          rows={4}
        />
        <button type="submit">Save Prompt</button>
      </form>
    </div>
  );
};

export default PromptBox;
