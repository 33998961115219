import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { TabStateContext, useSelection } from '../Hooks/TabsStateContext';


function Navbar({ navItems, activeTab, onTabChange }) {
  const { resetJobsTabState } = useSelection();
  return (
    <nav className="navbar">
      <ul>
        {navItems.map((item) => (
          <li key={item.value} className={activeTab === item.value ? 'active' : ''}>
            <Link
              to={item.route} 
              onClick={() => {
                onTabChange(item.value);
                if (item.value === 'jobs') { // Check if Jobs tab is clicked
                    resetJobsTabState();      // Reset the state
                }
              }} 
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Navbar;