import React from "react";
import { Route } from "react-router-dom";
import EmployeeBasePage from "../Pages/EmployeeAccess/EmployeeBasePage";
import EmpSkillsTab from "../Pages/EmployeeAccess/Tabs/SkillsTab";
import EmpLearningTab from "../Pages/EmployeeAccess/Tabs/LearningTab";
import EmpOpportunityTab from "../Pages/EmployeeAccess/Tabs/OpportunityTab";
import ProtectedRoute from "./ProtectedRoute";

export const EmployeeRoutes = () => {
  return (
    <Route
      path="/employee"
      element={
        <ProtectedRoute>
          <EmployeeBasePage />
        </ProtectedRoute>
      }
    >
      <Route path="skills" element={<EmpSkillsTab />} />
      <Route path="learning" element={<EmpLearningTab />} />
      <Route path="opportunity" element={<EmpOpportunityTab />} />
    </Route>
  );
};
