import React, { useState } from 'react';
import './SkillsDetails.css';
import { skillsTabContent } from '../../../../Assets/HRStaticContent.js'; // Import the content
import { useSelection } from "../../../../Hooks/TabsStateContext.js";
import DropdownMenu from '../../../../Components/DropdownMenu.js';
import ScrollableBox from '../../../../Components/ScrollableBox.js'; // Import the ScrollableBox component

function SkillsDetail() {
  const [searchTerm, setSearchTerm] = useState('');
  const { selectedJob, setSelectedJob, selectedItem, setSelectedItem } = useSelection();

  const dropdownItems = [
    { value: "level", label: "Level" },
    { value: "role", label: "Role" },
  ];

  // Example content for the scrollable boxes
  const leftContent = Array.from({ length: 20 }, (_, i) => <p key={i}>Detail {i + 1}</p>);
  const rightContent = Array.from({ length: 10 }, (_, i) => <p key={i}>Resource {i + 1}</p>);

  return (
    <div className="skills-detail">
      <div className="dropdown-section">
        <h1>{skillsTabContent.title}</h1>
        <DropdownMenu
          options={dropdownItems}
          placeholder="Select an option"
          value={selectedItem} // Pass down selectedItem as prop
          onItemSelect={(item) => {
            setSelectedItem(item); // Update selectedItem in context

            if (item === null || item.value === "") {
              setSelectedJob(null); // Set selectedJob to null if no item is selected
            } else {
              console.log('Selected Item:', item);
              // Logic to update selectedJob based on item
            }
          }}
        />
      </div>

      <div className="search-section">
        <input
          type="text"
          placeholder={selectedJob ? selectedJob.name : 'Search for a skill...'}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="content-layout">
        <div className="content-box-left">
          <h4>Left Content Box</h4>
          <ScrollableBox 
            height="400px" 
            width="100%" 
            content={leftContent} 
            className="scrollable-box" 
          />
        </div>

        <div className="content-box-right">
          <h4>Right Content Box</h4>
          <ScrollableBox 
            height="400px" 
            width="100%" 
            content={rightContent} 
            className="scrollable-box" 
          />
        </div>
      </div>
    </div>
  );
}

export default SkillsDetail;