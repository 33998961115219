import React, { useContext } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { AuthContext } from "../State/AuthContext";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

// react_app
let CLIENT_ID =
  "953011688496-85lfc71tkfa6qhbdjavvlhf4nrtg9um3.apps.googleusercontent.com";

if (process.env.NODE_ENV === 'production') {
  CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID;
}


const Login = () => {
  const { setEmail } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLoginSuccess = (response) => {
    try {
      const decodedResponse = jwtDecode(response.credential);
      const userEmail = decodedResponse.email;
      setEmail(userEmail);
      navigate("/");
    } catch (error) {
      console.error("Error decoding JWT:", error);
      alert("Failed to process login. Please try again.");
    }
  };

  const handleLoginFailure = (response) => {
    console.error("Login Failure:", response);
    alert("Google login failed. Please try again.");
  };

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <GoogleLogin
        onSuccess={handleLoginSuccess}
        onError={handleLoginFailure}
        useOneTap={true}
      />
    </GoogleOAuthProvider>
  );
};

export default Login;
