import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

function AccountabilityChart({ automationPotential }) {
  const unaffectedPercentage = 100 - automationPotential;

  const data = {
    labels: ["Accountability"],
    datasets: [
      {
        label: "Automation Potential",
        data: [automationPotential],
        backgroundColor: "#00C8C8",
        borderColor: "#00C8C8",
        borderWidth: 1,
        stack: "stack0",
      },
      {
        label: "Unaffected",
        data: [unaffectedPercentage],
        backgroundColor: "#ccc",
        borderColor: "#ccc",
        borderWidth: 1,
        stack: "stack0",
      },
    ],
  };

  const options = {
    indexAxis: "y", // Horizontal bar chart
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },
    },
    scales: {
      x: {
        stacked: true,
        beginAtZero: true,
        display: false, // Hide x-axis
      },
      y: {
        stacked: true,
        beginAtZero: true,
        display: false, // Hide y-axis
        barThickness: 40, // Increase bar thickness
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "80px" }}>
      <Bar data={data} options={options} />
    </div>
  );
}

export default AccountabilityChart;
