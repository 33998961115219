import React, { useState, useContext, useEffect } from "react";
import Header from "../../Components/Header"; 
import Navbar from '../../Components/Navbar';
import { Outlet, useLocation } from 'react-router-dom'; 
import { hrBasePageContent } from '../../Assets/HRStaticContent'; 
import { AuthContext } from '../../State/AuthContext'; 
import { TabStateProvider } from '../../Hooks/TabsStateContext'; 

const HRBasePage = () => {
  const [activeTab, setActiveTab] = useState(null);
  const location = useLocation();
  const { email } = useContext(AuthContext); 

  const navItems = [
    { name: 'Jobs', value: 'jobs', route: '/hr/jobs' },
    { name: 'Skills', value: 'skills', route: '/hr/skills' },
    { name: 'Learning', value: 'learning', route: '/hr/learning' },
    { name: 'Progress', value: 'progress', route: '/hr/progress' },
    { name: 'Opportunity', value: 'opportunity', route: '/hr/opportunity' }
  ];

  // Set active tab based on location
  useEffect(() => {
    const currentTab = navItems.find(item => location.pathname.includes(item.route));
    setActiveTab(currentTab ? currentTab.value : null);
  }, [location.pathname, navItems]);

  return (
    <div className="hr-base-page">
      <Header />
      <Navbar navItems={navItems} activeTab={activeTab} onTabChange={setActiveTab} />
      <main className="main-content">
        {activeTab ? (
          <Outlet />  
        ) : (
          <div className="default-page">
            <h2>{hrBasePageContent.title}</h2>
            {hrBasePageContent.paragraphs.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        )}
        {email && <p>Logged in as: {email}</p>} {/* Display the logged-in email */}
      </main>
    </div>
  );
};

export default HRBasePage;