import React, { useRef, useState, useContext, useEffect } from "react";
import MainContent from "../../../../Components/HRTabsMainContent.jsx";
import ScrollableBox from "../../../../Components/ScrollableBox";
import JobDetail from "./JobDetails.jsx";
import PromptBox from "../../../../Components/PromptBox"; // Import PromptBox
import { jobTabContent } from "../../../../Assets/HRStaticContent.js";
import { AuthContext } from "../../../../State/AuthContext.js";
import { useSelection } from "../../../../Hooks/TabsStateContext.js";
import { useNotification } from "../../../../State/NotificationContext";
import { API_ENDPOINTS } from "../../../../config/ApiConfig";
import "./JobsTab.css";

const JobsTab = ({ jobData }) => {
  const { triggerNotification } = useNotification();
  const { selectedJob, setSelectedJob, selectedItem, setSelectedItem } =
    useSelection();
  const fileInputRef = useRef(null);
  const { email } = useContext(AuthContext);

  const [selectedFile, setSelectedFile] = useState(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [isPromptSaved, setIsPromptSaved] = useState(false);
  const [savedPrompt, setSavedPrompt] = useState(""); // Add state for the saved prompt
  const [rolesUpdated, setRolesUpdated] = useState(false);
  const [currentSbUniqueId, setCurrentSbUniqueId] = useState(null);


  // Handle saving the prompt
  const handleSavePrompt = (prompt) => {
    setSavedPrompt(prompt); // Store the prompt in state
    setIsPromptSaved(true);
    triggerNotification("Prompt saved successfully!");
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "application/pdf" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    ) {
      setSelectedFile(file);
      triggerNotification("File uploaded successfully!");

      const formData = new FormData();
      formData.append("file", file);
      formData.append("email", email);
      formData.append("prompt", savedPrompt); // Use the saved prompt

      try {
        const response = await fetch(API_ENDPOINTS.UPLOAD, {
          method: "POST",
          body: formData,
        });

        if (!response.ok) throw new Error("Error uploading file");

        const data = await response.json();
        setAnalysisResult(data);
        triggerNotification("File uploaded and analyzed successfully!");

        setRolesUpdated((prev) => !prev); // Toggle rolesUpdated to refresh roles
      } catch (error) {
        console.error("Error uploading file:", error);
        triggerNotification("Error uploading and analyzing the file.");
      }
    } else {
      alert("Please select a PDF or DOCX file.");
    }
  };

  useEffect(() => {
    if (!selectedItem) {
      setSelectedJob(null);
    }
  }, [selectedItem, setSelectedJob]);

  useEffect(() => {
    console.log("Updated selectedJob:", selectedJob);
  }, [selectedJob]);

  if (!selectedItem || !selectedJob) {
    return (
      <div className="jobs-tab">
        <MainContent
          customText={jobTabContent}
          onJobSelect={(job) => {
            setSelectedJob(job);
            setCurrentSbUniqueId(job?.current_sb_unique_id || null); // Set the currentSbUniqueId
          }}
          email={email}
          rolesUpdated={rolesUpdated}
        />
        {selectedItem && (
          <div className="file-upload-section">
            <PromptBox onSavePrompt={handleSavePrompt} /> {/* Use PromptBox */}
            {/* File upload section */}
            <button
              onClick={() => fileInputRef.current.click()}
              className="upload-btn"
              disabled={!isPromptSaved}
            >
              Upload JD
            </button>
            <input
              type="file"
              accept=".pdf,.docx"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            {/* Display analysis result */}
            {analysisResult && (
              <ScrollableBox
                height="300px"
                width="100%"
                content={<pre>{JSON.stringify(analysisResult, null, 2)}</pre>}
                className="scrollable-box"
              />
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="jobs-tab">
      <JobDetail
        jobId={selectedJob.job_id}
        current_sb_unique_id={currentSbUniqueId}
        parent_sb_unique_id={selectedJob.parent_sb_unique_id}
        jobName={selectedJob.name}
        email={email}
        jdFileUri={analysisResult?.file_uri}
        analyzedJDUid={analysisResult?.analyzed_jd_uid}
        setCurrentSbUniqueId={setCurrentSbUniqueId}
      />
      {console.log(
        "Rendering JobDetail with jobId:",
        selectedJob.job_id,
        "jobName:",
        selectedJob.name
      )}
    </div>
  );
};

export default JobsTab;
