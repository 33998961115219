import React, { useState, useEffect } from "react";
import MainContent from "../../../Components/EmployeeTabsMainContent"; // Import MainContent
import { skillsTabContent } from "../../../Assets/EmployeeStaticContent"; // Import static content
import Slider from "react-slider"; // Custom slider component
import "./SkillsTab.css"; // Add CSS for styling

const SkillsTab = () => {
  const [skillsData, setSkillsData] = useState([]); // Holds skills data from API
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    // API call to fetch skills data
    const fetchSkillsData = async () => {
      setLoading(true);
      try {
        const response = await fetch("http://127.0.0.1:8080/api/skills");
        if (!response.ok) throw new Error("Failed to fetch skills data");

        const data = await response.json();
        setSkillsData(data.skills); // Assuming "skills" contains the array of skills
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSkillsData();
  }, []);

  // Handler to update the employee's validation for each skill
  const handleValidationChange = (skillId, newValue) => {
    setSkillsData((prevSkills) =>
      prevSkills.map((skill) =>
        skill.skills_id === skillId
          ? { ...skill, employee_accessed_proficiency: newValue }
          : skill
      )
    );
  };

  return (
    <div className="skills-tab">
      {/* Existing static content */}
      <MainContent customText={skillsTabContent} />

      <div className="skills-validation-section">
        <h3>Validate Your Skills</h3>

        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}

        {/* Display deadline */}
        <div className="validation-deadline">
          <p>Validation deadline: Sep 30, 2024</p>
          <div className="progress-bar">
            <div className="progress" style={{ width: "70%" }}></div>
          </div>
        </div>

        {/* Skills List */}
        <div className="skills-grid">
          {/* Header */}
          <div className="skills-grid-header">
            <div className="header-skill">Skill</div>
            <div className="header-proficiency">Proficiency Validation</div>
          </div>

          {skillsData.map((skill) => (
            <div className="skills-grid-row" key={skill.skills_id}>
              {/* First Column - Skill Name */}
              <div className="skill-name">{skill.skills_name}</div>

              {/* Second Column - Proficiency slider and inference */}
              <div className="proficiency-validation">
                <div className="proficiency-range">
                  <div className="proficiency-labels">
                    <span>0</span>
                    <span>7</span>
                  </div>
                  <Slider
                    className="slider"
                    min={0}
                    max={7}
                    value={skill.employee_accessed_proficiency}
                    onChange={(value) =>
                      handleValidationChange(skill.skills_id, value)
                    }
                    renderThumb={(props, state) => (
                      <div {...props}>{state.valueNow}</div>
                    )}
                  />
                </div>
                <div className="inferred-proficiency">
                  Inferred Proficiency: {skill.sb_proficiency_inferred}
                </div>

                {/* Explanation Dropdown for changes beyond +/- 1 */}
                {Math.abs(
                  skill.employee_accessed_proficiency -
                    skill.sb_proficiency_inferred
                ) > 1 && (
                  <div className="explanation-dropdown">
                    <select>
                      <option>Select Reason</option>
                      <option>Certification</option>
                      <option>Training</option>
                      <option>Experience</option>
                    </select>
                    <div className="upload-evidence">
                      <button className="upload-button">Upload</button>
                      <button className="video-button">Upload Video</button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SkillsTab;
// import React, { useState } from "react";
// import MainContent from "../../MainContent";
// import JobDetail from "./SkillsDetails"; // Import JobDetail

// const JobsTab = () => {
//   const [selectedJob, setSelectedJob] = useState(null);

//   // Custom text for the 'jobs' tab
//   const customText =
//     "View Skill Gaps for";

//   const handleJobSelect = (job) => {
//     setSelectedJob(job);
//   };

//   return (
//     <div className="jobs-tab">
//       {!selectedJob ? (
//         <MainContent customText={customText} onJobSelect={handleJobSelect} />
//       ) : (
//         <JobDetail job={selectedJob} />
//       )}
//     </div>
//   );
// };

// export default JobsTab;

// import React from "react";
// import MainContent from "../MainContent"; // Import MainContent
// import { skillsTabContent } from "../EmployeeStaticContent"; // Import the content

// const SkillsTab = () => {
//   return (
//     <div className="progress-tab">
//       {/* Pass the entire skillsTabContent object */}
//       <MainContent customText={skillsTabContent} />
//     </div>
//   );
// };

// export default SkillsTab;
