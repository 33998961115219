import React, { useEffect, useState } from 'react';
import './HRTabsMainContent.css';
import { useSelection } from '../Hooks/TabsStateContext';
import DropdownMenu from './DropdownMenu'; 
import SelectRole from './SelectRole'; 
import { API_ENDPOINTS } from '../config/ApiConfig';

function MainContent({ onJobSelect, customText, email, rolesUpdated }) {
  const { selectedItem, setSelectedItem } = useSelection();
  const [data, setData] = useState([]);

  const fetchRoles = async () => {
    console.log("Fetching roles with email:", email);
    try {
      const response = await fetch(`${API_ENDPOINTS.ROLES}?email=${encodeURIComponent(email)}`);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      console.log("Roles data fetched successfully:", data);
      setData(data);
    } catch (error) {
      console.error('Error fetching job data:', error);
    }
  };

  useEffect(() => {
    if (email) {
      console.log("Roles update detected or email provided, fetching roles...");
      fetchRoles();
    }
  }, [email, rolesUpdated]);

  const handleJobClick = (item) => {
    console.log("Role and version selected:", item);
    onJobSelect(item);
  };

  return (
    <div className="main-content">
      <div className="dropdown-section">
        <h1>{customText.title}</h1>
        <DropdownMenu 
          options={[{ value: "level", label: "Level" }, { value: "role", label: "Role" }]}
          placeholder="Select an option"
          value={selectedItem} 
          onItemSelect={(item) => setSelectedItem(item)}
        />
      </div>

      {!selectedItem && (
        <div className="content-section">
          {Object.entries(customText).map(([key, value], index) => index !== 0 && (
            Array.isArray(value) ? 
              <div key={key}><ul>{value.map((item, idx) => <p key={idx}>{item}</p>)}</ul></div> :
              <p key={key}>{value}</p>
          ))}
        </div>
      )}

      {selectedItem && (
        <SelectRole 
          placeholder="Select a role"
          roles={Array.isArray(data) ? data.map(item => ({
            id: item.job_id, 
            name: item.job_title,
            version_count: item.version_count,
          })) : []}
          email={email} 
          onRoleSelect={handleJobClick}
        />
      )}
    </div>
  );
}

export default MainContent;