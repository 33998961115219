import React from "react";
import "./ErrorPopup.css"; // Optional CSS file for styling the popup

function ErrorPopup({ errorMessage, onClose }) {
  return (
    <div className="error-popup-overlay">
      <div className="error-popup">
        <h2>Error</h2>
        <p>{errorMessage}</p>
        <button onClick={onClose}>OK</button>
      </div>
    </div>
  );
}

export default ErrorPopup;
