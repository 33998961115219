import * as monaco from "monaco-editor";
import React, { useEffect, useRef, useMemo, useState } from "react";
import "./JDDiffChecker.css";

function JDDiffChecker({
  originalJD,
  revisedJD,
  width = "100%",
  height = "100%",
  theme = "vs",
  onSaveChanges,
}) {
  const containerElement = useRef(null);
  const editor = useRef(null);
  const modifiedModel = useRef(null);
  const [hasChanges, setHasChanges] = useState(false);

  const style = useMemo(
    () => ({
      width,
      height,
    }),
    [width, height]
  );

  useEffect(() => {
    if (containerElement.current) {
      editor.current = monaco.editor.createDiffEditor(
        containerElement.current,
        {
          automaticLayout: true,
          wordWrap: "on",
          theme,
          renderIndicators: true,
        }
      );

      const originalModel = monaco.editor.createModel(
        originalJD || "",
        "markdown"
      );
      modifiedModel.current = monaco.editor.createModel(
        revisedJD || "",
        "markdown"
      );

      editor.current.setModel({
        original: originalModel,
        modified: modifiedModel.current,
      });

      modifiedModel.current.updateOptions({ readOnly: false });
      originalModel.updateOptions({ readOnly: true });

      const onChangeDisposable = modifiedModel.current.onDidChangeContent(
        () => {
          const updatedText = modifiedModel.current.getValue();
          setHasChanges(
            updatedText !== originalJD && updatedText !== revisedJD
          );
        }
      );

      return () => {
        if (editor.current) editor.current.dispose();
        if (onChangeDisposable) onChangeDisposable.dispose();
      };
    }
  }, [originalJD, revisedJD, theme]);

  const handleSave = () => {
    if (hasChanges && onSaveChanges) {
      const updatedText = modifiedModel.current.getValue();
      onSaveChanges(updatedText);
      setHasChanges(false);
    }
  };

  return (
    <div style={{ width, height, display: "flex", flexDirection: "column" }}>
      <div className="jd-labels">
        <span className="jd-label">Original JD</span>
        <span className="jd-label">Skillsbridge JD (Editable)</span>
      </div>
      <div ref={containerElement} className="react-monaco-editor-container" />
      <div className="save-button-container">
        <button
          className="save-button"
          onClick={handleSave}
          disabled={!hasChanges}
        >
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default JDDiffChecker;
