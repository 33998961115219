import React, { useState } from "react";
import Header from "../../Components/Header"; 
import Navbar from '../../Components/Navbar';
import { Outlet, useLocation } from 'react-router-dom'; // Use Outlet and location
import { jobBasePageContent } from '../../Assets/EmployeeStaticContent'; // Import the content


const EmployeeBasePage = () => {
  const [activeTab, setActiveTab] = useState(null);
  const location = useLocation();

  const navItems = [
    { name: 'Skills', value: 'skills', route: '/employee/skills' },
    { name: 'Learning', value: 'learning', route: '/employee/learning' },
    { name: 'Opportunity', value: 'opportunity', route: '/employee/opportunity' }
  ];

  // Check if a tab is active based on the current route
  const isTabActive = navItems.some(item => location.pathname.includes(item.route));

  return (
    <div className="employee-base-page">
      <Header />
      <Navbar navItems={navItems} activeTab={activeTab} onTabChange={setActiveTab} />
      <main className="main-content">
        {isTabActive ? (
          <Outlet />  // Render the selected tab's content
        ) : (
          <div className="default-page">
            <h2>{jobBasePageContent.title}</h2>
            {jobBasePageContent.paragraphs.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        )}
      </main>
    </div>
  );
};

export default EmployeeBasePage;